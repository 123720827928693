<template>
  <v-container fluid class="mb-16">
    <v-card height="100%">
      <v-row>
        <v-card-text style="background: white; height: 100%">
          <v-row class="ml-7" style="width: 96%">
            <v-col>
              <template>
                <v-card>
                  <v-data-table
                    :headers="dessertHeaders"
                    :items="sellers"
                    :search="search"
                    sort-by="id"
                    :single-expand="singleExpand"
                    :expanded.sync="expanded"
                    item-key="id"
                    class="elevation-1"
                    style="background: #f5f5f5"
                  >
                    <template v-slot:top>
                      <v-card-title style="font-size: 26px">
                        All Merchant
                        <v-spacer></v-spacer>
                        <div class="pr-5 pb-5 pt-2">
                          <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            filled
                            rounded
                            dense
                            hide-details
                          ></v-text-field>
                        </div>
                        <v-switch
                          v-model="singleExpand"
                          label="Single expand"
                          class="mt-2"
                        ></v-switch>
                      </v-card-title>
                    </template>
                    <!-- <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <b>Order Refrence: </b> {{ item.id }} <br />
                        <b>User ID : </b> {{ item.user_id }}<br />
                        <b>Date : </b> {{ item.ordered_date }}<br />
                        <b>Host Site : </b> {{ item.host }}<br />
                        <b>Order Status : </b> {{ item.order_status }}<br />
                        <b>Delivery Type : </b> {{ item.delivery_status }}<br />
                        <b>Copun Code : </b> {{ item.coupon_code }}<br />
                        <b>Copun Percentage : </b> {{ item.coupon_percentage
                        }}<br />
                        <b>Total Point : </b> {{ item.point_total }}<br />
                      </td>
                    </template> -->

                    <template v-slot:item.actions="{ item }">
                      <v-btn
                        color="primary"
                        style="padding-left: 0px !important"
                        text
                        @click="viewDetail(item)"
                        >Detail</v-btn
                      >
                    </template>

                    <template v-slot:item.admin_status="{ item }">
                      <v-chip :color="getColor(item.admin_status)" dark>
                        {{ item.admin_status }}
                      </v-chip>
                    </template>
                  </v-data-table>
                </v-card>
              </template>
            </v-col>
          </v-row>
        </v-card-text></v-row
      ></v-card
    ></v-container
  >
</template>


<script>
export default {
  data: () => ({
    expanded: [],
    search: "",
    singleExpand: false,
    dessertHeaders: [
      {
        text: "Email",
        align: "start",
        value: "email",
        sortable: false,
      },
      { text: "Name", value: "name", sortable: false },
      { text: "Phone Number", value: "phone_number", sortable: false },
      { text: "Address ", value: "address", sortable: false },
      { text: "Loaction", value: "location", sortable: false },
      { text: "Approved", value: "total_approved_products", sortable: false },
      { text: "Calceled", value: "total_cancelled_products", sortable: false },
      { text: "Pending", value: "total_pending_products", sortable: false },
      { text: "View", value: "actions", sortable: false },
      // { text: 'test', value: 'tes' },
      //{ text: "", value: "data-table-expand" },
    ],

    sellers: [],

  }),

  methods: {
    initialize() {
      axios.get("product/seller_list/").then((response) => {
        this.sellers = response.data.data;
     
      });

    },
    viewDetail(item) {
   
      this.$router.push({
        path: "/supportdashboard/pages/merchantdetails",
        query: { id: item.id },
      });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>


<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>